import { getType } from 'mobx-state-tree'
import { BaseModel } from 'store/utils/base-model'
import { Org } from 'store/modules/orgs'
import { OrgPolicy } from './org-policy'
import { Position } from 'store/modules/positions'
import { PositionPolicy } from './position-policy'
import { Skill } from 'store/modules/skills'
import { SkillPolicy } from './skill-policy'
import { SkillVariant } from 'store/modules/skill-variants'
import { SkillVariantPolicy } from './skill-variant-policy'
import { store } from 'store/index'
import { Team } from 'store/modules/teams'
import { TeamPolicy } from './team-policy'
import { User } from 'store/modules/users'
import { Win } from 'store/modules/wins'
import { WinPolicy } from './win-policy'

// TODO: Figure out how to avoid defining this logic here and in the implementation
export type PolicyFor<Type extends BaseModel> = Type extends Org
  ? OrgPolicy
  : Type extends Position
  ? PositionPolicy
  : Type extends Skill
  ? SkillPolicy
  : Type extends SkillVariant
  ? SkillVariantPolicy
  : Type extends Team
  ? TeamPolicy
  : Type extends Win
  ? WinPolicy
  : never

export function can<T extends BaseModel>(user: User, object: T): PolicyFor<T> {
  if (Org.is(object)) return new OrgPolicy(user, object) as PolicyFor<T>
  if (Position.is(object))
    return new PositionPolicy(user, object) as PolicyFor<T>
  if (Skill.is(object)) return new SkillPolicy(user, object) as PolicyFor<T>
  if (SkillVariant.is(object))
    return new SkillVariantPolicy(user, object) as PolicyFor<T>
  if (Team.is(object)) return new TeamPolicy(user, object) as PolicyFor<T>
  if (Win.is(object)) return new WinPolicy(user, object) as PolicyFor<T>
  throw new Error(`Unknown object type: ${getType(object).name}`)
}

export function usersWhoCan<T extends BaseModel>(
  model: T,
  action: keyof PolicyFor<T>
): User[] {
  return store.users.filtered((user) => !!can(user, model)[action])
}
