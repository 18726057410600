import { Win } from 'store/modules/wins'
import { BasePolicy } from '../base-policy'

export class WinPolicy extends BasePolicy<Win> {
  public get edit() {
    return this.user.id === this.object.reporter.id
  }

  public get delete() {
    return (
      this.object.reporter.id == this.user.id ||
      this.object.winners.some((user) => user.id === this.user.id)
    )
  }
}
