import { BasePolicy } from '../base-policy'
import { Team } from 'store/modules/teams'

export class TeamPolicy extends BasePolicy<Team> {
  public get edit() {
    return (
      this.user.isAdminOfOrg(this.orgId) || this.object.hasEditor(this.user.id)
    )
  }

  private get orgId() {
    return this.object.org.id
  }
}
