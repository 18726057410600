import { Position } from 'store/modules/positions'
import { Team } from 'store/modules/teams'
import { BasePolicy } from '../base-policy'

export class PositionPolicy extends BasePolicy<Position> {
  public get edit() {
    const team: Team = this.object.team
    const isAdmin = this.user.isAdminOfOrg(team.org.id)
    const isEditor = team.hasEditor(this.user.id)
    return isAdmin || isEditor
  }
}
